import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EStatus } from '@common/definitions';
import { SelectInputHookForm } from '@common/components/form/inputs/select';
import Spinner from '@common/components/spinner';
import { Course } from '@modules/learning/types/objects';
import getStatus from '@modules/learning/utils/get-status';

type CourseStatusProps = {
  course: Course;
  handleStatusChange: (published: boolean, archived?: boolean) => void;
};

const CourseStatus = ({ course, handleStatusChange }: CourseStatusProps) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState<boolean>(false);

  const statusOptions = useMemo(() => [
    { value: EStatus.DRAFT, label: t('common:status_draft') },
    { value: EStatus.ARCHIVED, label: t('common:status_archived') },
    { value: EStatus.LIVE, label: t('common:status_live') },
  ], [course, t]);

  const defaultValues = useMemo(() => ({
    status: getStatus(course),
  }), [course]);

  const { control, register } = useForm({
    mode: 'all',
    defaultValues,
  });

  const updateStatus = useCallback(async (status: EStatus) => {
    if (getStatus(course) === status) return;
    setUpdating(true);
    if (status === EStatus.LIVE) {
      await handleStatusChange(true, false);
    } else {
      await handleStatusChange(false, status === EStatus.ARCHIVED);
    }
    setUpdating(false);
  }, [handleStatusChange, course?.published]);

  return (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-relative">
      <SelectInputHookForm
        control={control}
        clearable={false}
        className="FormStatusSelector"
        options={statusOptions}
        disabled={updating}
        {...register('status', {
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            updateStatus(event.target.value as EStatus);
          },
        })}
      />
      {updating && <Spinner className="tw-absolute tw-top-0 tw-right-10" />}
    </div>
  );
};

export default CourseStatus;
