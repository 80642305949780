import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Switch, Route, useHistory, useParams } from 'react-router';
import { DndProvider } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { AlertService } from '@services/alert';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Permission from '@common/components/permission';
import Placeholder from '@common/components/placeholder';
import List from '@common/components/list';
import Bar from '@common/components/bar';
import Spinner from '@common/components/spinner';
import { usePredicateState } from '@common/hooks/use-predicate-state';
import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar/top-navigation-bar';
import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';
import OnboardingItem from '../../components/onboarding-item';
import OnboardingSettingsForm from '../../forms/onboarding-settings';
import OnboardingStatistics from './statistics';

import { changeCourseTypeAction, duplicateCourse as duplicateCourseAction } from '../../actions';
import fetchCoursesAction from '../../actions/fetch-courses';
import updateCourseAction from '../../actions/update-course';
import removeCourseAction from '../../actions/remove-course';
import updateCoursesOrderAction from '../../actions/update-courses-order';

import dndManager from '@common/utils/dnd-manager';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as coursesSelector from '../../selectors/courses';
import * as userSelector from '../../../core/selectors/logged-user';
import { EPermissions, EPlanPackageConfig, EStatus } from '../../../../common/definitions';
import { Api } from '@common/services/api';
import { getFilteredItems, getTitleKey } from '../academy/courses';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { TrackComponent, EEventNames } from '../../../../../client/analytics';

import type { StoreState } from '../../../../common/types/store';
import { Course } from '@modules/learning/types/objects';
import { Network, Organisation, Team } from '@common/types/objects';
import { ApiResponse } from '@common/services/api/types';

const mapStateToProps = (state: StoreState) => ({
  permissions: userSelector.permissions(state),
  courses: coursesSelector.onboarding(state) as unknown as Course[],
  organisation: organisationSelector.selected(state),
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
});

const mapDispatchToProps = {
  changeCourseType: changeCourseTypeAction,
  fetchCourses: fetchCoursesAction,
  updateCourse: updateCourseAction,
  updateCoursesOrder: updateCoursesOrderAction,
  removeCourse: removeCourseAction,
  duplicateCourse: duplicateCourseAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
  networks: Network[];
  organisation: Organisation;
  functions: any;
  fetchCourses: any;
  duplicateCourse: any;
  updateCourse: any;
  updateCoursesOrder: any;
  removeCourse: any;
  changeCourseType: any;
};

type CoursesCountMeta = {
  counts: {
    total: number;
  }
};

const LearningOnboardingContainer = ({
  courses, organisation, networks, functions, permissions,
  fetchCourses, duplicateCourse, updateCourse, removeCourse, updateCoursesOrder, changeCourseType,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ status?: EStatus }>();
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState('');
  const [amountOfAcademyCourses, setAmountOfAcademyCourses] = useState<number | undefined>(undefined);
  const [order, setOrder] = useState<string[]>(courses.map(({ id }) => id));
  const [networksData, setNetworksData] = useState<Network[]>([]);
  const [functionsData, setFunctionsData] = useState<Team[]>([]);

  const [predicates, setPredicates] = usePredicateState({
    networks: networksData,
    functions: functionsData,
  });

  useEffect(() => {
    const init = async () => {
      const [data, meta, newNetworks, newFunctions] = await Promise.all([
        fetchCourses(),
        Api.get<ApiResponse<{}, CoursesCountMeta>>(`/v2/organisations/${organisation.id}/courses?limit=0&type=academy`),
        Api.get(`/v4/organisations/${organisation.id}/networks?include_all=true`),
        Api.get(`/v3/organisations/${organisation.id}/functions`),
      ]);
      setAmountOfAcademyCourses(meta.meta?.counts.total);
      setOrder(data.items.map(({ id }: Course) => id));
      setNetworksData(newNetworks.data as Network[]);
      setFunctionsData(newFunctions.data as Team[]);
      setIsFetching(false);
    };
    init();
  }, [
    organisation, fetchCourses, setIsFetching,
    setAmountOfAcademyCourses, setOrder, setPredicates,
  ]);

  const filteredItems = getFilteredItems(courses, predicates, search, order, params);

  const {
    isAvailable: canCreateCourses, showUpgradeModal: showUpgradeToCreateCoursesModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY_ONBOARDING, filteredItems?.length);
  const {
    isAvailable: canMoveCoursesToAcademy, showUpgradeModal: showUpgradeToMoveModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.ACADEMY, amountOfAcademyCourses);

  const onDuplicate = useCallback(async (courseId) => {
    if (canCreateCourses) {
      try {
        await duplicateCourse(courseId);
        const data = await fetchCourses();
        setOrder(data.items.map(({ id }: { id: string }) => id));
        AlertService.success(t('learning:duplicated_course'));
      } catch (err) {
        AlertService.warning(t('learning:warning_duplicate_course'));
      }
    } else {
      showUpgradeToCreateCoursesModal();
    }
  }, [
    duplicateCourse, canCreateCourses, order,
    fetchCourses, showUpgradeToCreateCoursesModal, setOrder, t,
  ]);

  const onDelete = useCallback(async (id) => {
    await removeCourse(id);
    AlertService.success(t('learning:removed_course'));
  }, [removeCourse, t]);

  const onMoveCourseToAcademy = useCallback(async (courseId: string) => {
    if (canMoveCoursesToAcademy) {
      try {
        await changeCourseType(courseId);
        setAmountOfAcademyCourses((amountOfAcademyCourses && amountOfAcademyCourses + 1) || 1);
        setOrder((previousOrder) => previousOrder.filter((id) => id !== courseId));
      } catch (e) {
        AlertService.error(t('learning:unable_to_move_course'));
      }
    } else {
      showUpgradeToMoveModal();
    }
  }, [
    amountOfAcademyCourses, canMoveCoursesToAcademy, changeCourseType,
    showUpgradeToMoveModal, setAmountOfAcademyCourses, setOrder, t,
  ]);

  const onChangeOrder = useCallback((sourceId: string, targetId: string) => {
    const index = R.indexOf(targetId, order);
    setOrder(R.insert(index, sourceId, R.reject(R.equals(sourceId), order)));
  }, [order, setOrder]);

  const onDrop = useCallback(async () => {
    try {
      await updateCoursesOrder(order);
      AlertService.success(t('learning:saved_order'));
    } catch (response: any) {
      AlertService.forStatus(response.status_code, {
        warning: t('learning:warning_saving_order'),
        error: t('learning:error_saving_order'),
      });
    }
  }, [updateCoursesOrder, order, t]);

  const onPublish = useCallback((id: string, publish: boolean) => {
    if (publish) {
      history.push(`/admin/learning/courses/${id}/publish/onboarding`);
    } else {
      updateCourse(id, { published: false });

      AlertService.success(t('learning:unpublished_course'));
    }
  }, [history, updateCourse, t]);

  const onArchive = useCallback((id: string, archived: boolean) => {
    updateCourse(id, { archived }, true);
    AlertService.success(archived ? t('learning:course_archived') : t('learning:course_unarchived'));
  }, [updateCourse, t]);

  const onCreateCourse = useCallback(() => {
    if (canCreateCourses) {
      history.push('/admin/learning/onboarding/create');
    } else {
      showUpgradeToCreateCoursesModal();
    }
  }, [canCreateCourses, history, showUpgradeToCreateCoursesModal]);

  return (
    <Container name="Onboarding">
      <TopNavigationBar
        title={t('learning:breadcrumb_onboarding')}
        learnMoreHref={t('learning:onboarding_learn_more')}
        action={permissions.includes(EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE) && (
          // @ts-expect-error
          <OnboardingSettingsForm organisation={organisation}>
            <Button><Trans i18nKey="learning:onboarding_settings" /></Button>
          </OnboardingSettingsForm>
        )}
      />
      <Container.Content>
        <Switch>
          <Route
            path="/admin/learning/onboarding/statistics"
            component={OnboardingStatistics}
          />
          <Route>
            {/* @ts-expect-error */}
            <TrackComponent eventName={EEventNames.VISITED_ONBOARDING_PAGE}>
              <Bar>
                <h2 className="pull-left">
                  <Trans
                    i18nKey={getTitleKey(params.status)}
                    values={{ count: filteredItems.length }}
                  />
                </h2>
                <div className="pull-right">
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE}>
                    <Button
                      type="primary"
                      iconRight="add"
                      size="large"
                      onClick={onCreateCourse}
                    >
                      <Trans i18nKey="learning:onboarding_add_course" />
                    </Button>
                  </Permission>
                </div>
              </Bar>
              {isFetching ? (
                <Spinner centered size="large" />
              ) : (
                <>
                  <Bar>
                    <FiltersToolbar
                      predicates={predicates}
                      setPredicates={setPredicates}
                      organisation={organisation}
                      debounce={false}
                      onSearch={setSearch}
                    />
                  </Bar>
                  <DndProvider manager={dndManager}>
                    <List
                      className="OnboardingCourses"
                      items={filteredItems}
                      renderRow={OnboardingItem}
                      rowProps={{
                        onArchive,
                        onOpen: (id: string) => history.push(params.status ?
                          `/admin/learning/courses/${id}?backUrl=/admin/learning/onboarding/filter/${params.status}` :
                          `/admin/learning/courses/${id}`
                        ),
                        onChangeOrder,
                        onDrop,
                        onPublish,
                        onDelete,
                        onDuplicate,
                        onChangeCourseType: onMoveCourseToAcademy,
                        organisation,
                        networks,
                        functions,
                        showStatus: !params.status,
                      }}
                      placeholder={(
                        <Placeholder
                          image="/static/images/courses-placeholder.svg"
                          title={t('learning:onboarding_placeholder_title')}
                          description={t('learning:onboarding_placeholder_description')}
                          action={(
                            <Button
                              iconRight="add"
                              type="primary"
                              onClick={() => history.push('/admin/learning/onboarding/create')}
                            >
                              <Trans i18nKey="learning:onboarding_placeholder_action" />
                            </Button>
                          )}
                        />
                      )}
                    />
                  </DndProvider>
                </>

              )}
            </TrackComponent>
          </Route>
        </Switch>
      </Container.Content>
    </Container>
  );
};

export default connector(LearningOnboardingContainer);
